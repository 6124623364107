<template>
  <div>
    <Navbar />

    <div class="medContainer">
      <PathPage class="pt-3 pb-2" />
      <div v-if="singleNews != null">
        <div v-if="lang == 'ka'">
          <vue-headful
            :title="[removeHTMLForTitle(singleNews.item.titleKA)]"
            :description="[removeHTMLForTitle(singleNews.item.descriptionKA)]"
            :keywords="singleNews.item.keywords.toString()"
            image=""
            lang=""
            ogLocale=""
            url=""
          />
        </div>
        <div v-else>
          <vue-headful
            :title="[removeHTMLForTitle(singleNews.item.titleEN)]"
            :description="[removeHTMLForTitle(singleNews.item.descriptionEN)]"
            :keywords="singleNews.item.keywords.toString()"
            image=""
            lang=""
            ogLocale=""
            url=""
          />
        </div>
        <div class="row">
          <div class="col col-lg-8">
            <h4
              style="
                font-family: mtavruliBOLD;
                color: #666;
                letter-spacing: 2px;
              "
              role="heading"
            >
              <span
                class="blogTitle"
                style="font-family: mtavruliBOLD"
                v-if="lang == 'ka'"
                v-html="singleNews.item.titleKA"
              >
              </span>
              <span
                class="blogTitle"
                style="font-family: mtavruliBOLD"
                v-else
                v-html="singleNews.item.titleEN"
              >
              </span>
            </h4>

            <p
              style="font-family: mtavruli; color: #7f5496"
              class="mt-0 mb-0"
              role="date"
            >
              <span v-if="lang == 'ka'">{{ singleNews.item.dateKA }}</span>
              <span v-else>{{ singleNews.item.dateEN }}</span>
            </p>

            <div
              class="pl-0 pr-0 col-12 sliderContainer"
              v-if="singleNews.item.images"
            >
              <div class="slider" v-if="!isLoading">
                <hooper
                  :progress="true"
                  :autoPlay="true"
                  :playSpeed="3000"
                  :hover="true"
                  style="border-radius: 0px"
                >
                  <slide v-for="item in singleNews.item.images" :key="item._id">
                    <div class="conatiner">
                      <img
                        :src="`${server_url}/${item.image}`"
                        :alt="
                          lang == 'ka'
                            ? removeHTML(singleNews.item.titleKA)
                            : removeHTML(singleNews.item.titleEN)
                        "
                      />
                    </div>
                  </slide>
                  <hooper-navigation slot="hooper-addons"></hooper-navigation>
                  <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
              </div>
              <div v-else>
                <b-skeleton-img height="500px" width="900px" />
              </div>
            </div>

            <div class="w-full mt-3 textScroll">
              <span
                class="simpleText"
                v-if="lang == 'ka'"
                v-html="singleNews.item.descriptionKA"
                >{{ singleNews.item.descriptionKA }}</span
              >
              <span
                class="simpleText"
                v-else
                v-html="singleNews.item.descriptionEN"
              >
                {{ singleNews.item.descriptionEN }}
              </span>
            </div>

            <div
              class="mt-5"
              v-if="singleNews.item.video && singleNews.item.video != 'null'"
            >
              <iframe
                width="100%"
                height="415"
                :src="singleNews.item.video"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <div class="shareSide pb-3 pt-3 mb-3 mt-3">
              <div class="">
                <div
                  class="
                    d-flex
                    pb-1
                    pt-1
                    justify-content-center
                    sharingSide
                    align-items-center
                    mtavruli
                    blackColor
                  "
                >
                  <span class="pr-3 mtavruli d-flex">
                    {{ $t("front.share") }}
                  </span>
                  <div class="d-flex">
                    <ShareNetwork
                      v-for="network in networks"
                      :network="network.network"
                      :key="network.network"
                      :url="shareNetwork"
                      :title="
                        lang == 'ka'
                          ? removeHTML(singleNews.item.titleKA)
                          : removeHTML(singleNews.item.titleEN)
                      "
                      :quote="
                        lang == 'ka'
                          ? joinStrings([
                              removeHTML(singleNews.item.titleKA),
                              removeHTML(singleNews.item.descriptionKA),
                            ])
                          : joinStrings([
                              removeHTML(singleNews.item.titleEN),
                              removeHTML(singleNews.item.descriptionEN),
                            ])
                      "
                    >
                      <div>
                        <img
                          style="width: 31px"
                          class="p-1"
                          :src="network.icon"
                          :alt="
                            lang == 'ka' ? 'სოციალური ქსელი' : 'social network'
                          "
                        />
                      </div>
                    </ShareNetwork>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col col-lg-4 mb-5">
            <div>
              <p
                style="
                  font-family: mtavruliBOLD;
                  color: #7f5496;
                  letter-spacing: 0.5px;
                  text-transform: uppercase;
                "
                class="mb-0"
              >
                {{ $t("front.search") }}
              </p>
              <div
                style="height: 2px; background-color: #7f5496; width: 60px"
              ></div>

              <div class="mt-2 d-flex">
                <input
                  style="
                    width: 650px;
                    height: 40px;
                    border-radius: 0px !important;
                    border: 2px solid #b3b3b3;
                    padding-left: 5px;
                    font-size: 13px;
                  "
                  @click="showModal()"
                  placeholder="..."
                />
                <button
                  @click="showModal()"
                  style="
                    width: 100%;
                    background-color: #7f5496;
                    border: none;
                    outline: none;
                    color: #fff;
                    font-family: dejavu;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                  "
                >
                  {{ $t("front.search") }}
                </button>
              </div>
              <b-modal
                v-model="modalShow"
                ok-only
                ok-variant="secondary"
                :ok-title="$t('front.close')"
              >
                <SearchModalNews />
              </b-modal>
              <div
                class="w-full mt-3"
                style="height: 1px; background-color: lightgray"
              ></div>

              <div class="mt-3">
                <p
                  style="
                    font-family: mtavruliBOLD;
                    color: #7f5496;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                  "
                  class="mb-0"
                >
                  {{ $t("front.lastNews") }}
                </p>
                <div
                  style="height: 2px; background-color: #7f5496; width: 60px"
                ></div>

                <div class="photoWtext" v-if="isLoading == false">
                  <router-link
                    v-for="item in singleNews.latestNews"
                    :key="item._id"
                    class="d-flex"
                    style="cursor: pointer; text-decoration: none"
                    :to="'/news' + '/' + item.slug"
                  >
                    <div class="mt-2 imgSide">
                      <div v-if="item.images && item.video == null">
                        <img
                          :src="`${server_url}/${item.images[0].image}`"
                          :alt="
                            lang == 'ka'
                              ? removeHTML(singleNews.item.titleKA)
                              : removeHTML(singleNews.item.titleEN)
                          "
                          style="
                            height: 76px;
                            width: 142.22px;
                            border-radius: 0px;
                          "
                        />
                      </div>
                      <div v-if="item.video != 'null'" class="latestIframe">
                        <iframe
                          width="142.22px"
                          height="76"
                          :src="item.video"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                    <div class="textSide">
                      <p
                        class="textSideP"
                        :style="`
                      
                          font-family: mtavruli;
                          color: #7f5496;
                          font-size: 16px;
                          margin-left: 25px;
                          margin-top: 10px;
                          font-weight: ${
                            item.slug === singleNews.item.slug ? 'bold' : '500'
                          };
                        `"
                      >
                        <span
                          style="font-family: mtavruli"
                          class="headerTitle"
                          v-if="lang == 'ka'"
                          v-html="item.titleKA"
                        ></span>
                        <span
                          class="headerTitle"
                          style="font-family: mtavruli"
                          v-else
                          v-html="item.titleEN"
                        ></span>
                      </p>
                    </div>
                    <div
                      class="w-full mt-3"
                      style="height: 1px; background-color: lightgray"
                    ></div>
                  </router-link>
                </div>

                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 20px;
                    flex-direction: column;
                  "
                >
                  <div v-for="item in [1, 2, 3, 4]" :key="item">
                    <b-skeleton-img height="80px" width="200px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from "hooper";
import "hooper/dist/hooper.css";
import image from "../../../../assets/icons/facebook7.png";
import imagelink from "../../../../assets/icons/in7.png";
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import PathPage from "../../PathPage.vue";
import SearchModalNews from "./SearchModalNews.vue";
import axios from "axios";
import env from "../../../../env.json";
export default {
  name: "NewsDetailPage",
  components: {
    Navbar,
    Footer,
    PathPage,
    Hooper,
    Slide,
    HooperNavigation,
    SearchModalNews,
    HooperPagination,
  },
  data() {
    return {
      modalShow: false,
      data: {},
      item: null,

      latestNews: [],
      shareNetwork: env.social_network_share,
      lang: "ka",
      singleNews: null,
      isLoading: false,
      server_url: env.server_url,
      networks: [
        {
          network: "facebook",
          name: "Facebook",
          icon: image,
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: imagelink,
          color: "#007bb5",
        },
      ],
    };
  },
  watch: {
    search: function () {},
  },
  methods: {
    removeHTML: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    removeHTMLForTitle: function (string) {
      return string.replace(/<\/?[^>]+>/gi, "");
    },
    joinStrings: function (string) {
      return string.join(" - ");
    },
    handleNavigate: function (slug) {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/once/news/${slug}/null/null`)
        .then((result) => {
          this.singleNews = {
            item: result.data.item,

            latestNews: result.data.latestNews,
          };
          this.isLoading = false;
        });
    },
    showModal: function () {
      this.modalShow = !this.modalShow;
    },
  },
  mounted() {
    axios
      .get(`${env.host}/get/once/news/${this.$route.params.slug}/null/null`)
      .then((result) => {
        this.singleNews = {
          item: result.data.item,
          latestNews: result.data.latestNews,
        };

        this.shareNetwork = `${env.social_network_share}/news/${this.$route.params.slug}`;
      });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.blogTitle >>> * {
  font-family: mtavruliBOLD;
  color: #666666 !important;
}
.simpleText >>> * {
  color: #666666 !important;
}
.headerTitle >>> * {
  font-family: mtavruli;
  color: #666666 !important;
}
::v-deep .modal-content {
  border-radius: 0px;
}
::-webkit-scrollbar {
  width: 5px;
}
.imgSide {
  height: 100px;
}
.imgSide img {
  width: 10px;
}
.textSide {
  font-size: 16px;
}
.textSideP {
  word-break: break-word !important;
}

.photoWtext {
  width: 100% !important;
}
::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #a480b7a6;
}

::-webkit-scrollbar-thumb:hover {
  background: #a480b7;
}

::v-deep .btn-secondary {
  color: #fff;
  background-color: #a480b7 !important;
  border-color: #a480b7 !important;
  border-radius: 2px !important;
}

.medContainer {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  min-height: calc(100vh - 190px);
}
.sharingSide {
  border-bottom: 1px solid #ab7373;
  border-top: 1px solid #b3b3b3;
}
.sliderContainer {
  display: flex;
  align-items: center;
}
.slider {
  width: 100%;
  position: relative;
  height: auto;
  border-radius: 0px;
  margin: auto;
  background-size: cover;
  margin-top: 10px;
}
.slider img {
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center center;
  width: 100%;
  height: 550px;
  border-radius: 0px;
}
.hooper-slide {
  position: relative;
  border-radius: 0px;
}
.hooper {
  height: 400px;
  border-radius: 0px;
}
::v-deep .hooper-list {
  border-radius: 0px;
}
::v-deep .hooper-progress-inner {
  background-color: #a480b7;
}
::v-deep .hooper-indicator.is-active {
  background: #a480b7;
  width: 20px;
  height: 3px;
  border-radius: 0px;
}
::v-deep .hooper-indicator {
  background: white;
  width: 20px;
  height: 4px;
  border-radius: 0px;
}
::v-deep input:focus-visible {
  outline: none !important;
}
.textScroll {
  overflow-y: scroll;
  max-height: 400px;
  padding-right: 10px;
}
@media all and (max-width: 1500px) {
  .medContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media all and (max-width: 1056px) {
  .latestIframe iframe {
    width: 136px;
  }
  .imgSide img {
    width: 136px;
  }
}
@media all and (max-width: 999px) {
  .latestIframe iframe,
  .imgSide img {
    width: 127px !important;
  }
}
@media all and (max-width: 991px) {
  .latestIframe iframe,
  .imgSide img {
    width: 142px !important;
  }
  .textScroll {
    overflow-y: hidden;
    max-height: none;
    padding-right: 0px;
  }
  .shareSide {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 576px) {
  .medContainer h3 {
    font-size: 25px;
  }
}
@media all and (max-width: 368px) {
  .latestIframe iframe,
  .imgSide img {
    width: 8.7rem !important;
  }
}
@media all and (max-width: 323px) {
  .medContainer h3 {
    font-size: 20px;
  }
}
@media all and (max-width: 345px) {
  .latestIframe iframe,
  .imgSide img {
    width: 131px !important;
  }
}
@media all and (max-width: 328px) {
  .latestIframe iframe,
  .imgSide img {
    width: 123px !important;
  }
}
@media all and (max-width: 318px) {
  .latestIframe iframe,
  .imgSide img {
    width: 118px !important;
  }
}
@media all and (max-width: 290px) {
  .latestIframe iframe,
  .imgSide img {
    width: 103px !important;
  }
}
</style>